import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './App.css';

function App() {
  const [showMainContent, setShowMainContent] = useState(false);

  const handleExploreClick = () => {
    setShowMainContent(true);
  };

  return (
    <div>
      {!showMainContent ? (
        <IntroContent onExploreClick={handleExploreClick} />
      ) : (
        <MainContent />
      )}
    </div>
  );
}

const IntroContent = ({ onExploreClick }) => (
  <div className="intro-content-wrapper">
    <h1 className="intro-title">Hi, I’m 4igz – Software Developer & Problem Solver</h1>
    <p className="intro-description">
      I specialize in creating robust, efficient, and user-focused solutions. My portfolio showcases
      projects that demonstrate my expertise in software development, game design, and system engineering.
    </p>
    <div className="intro-body">
      <p>
        From designing interactive applications to building scalable back-end systems, my work reflects
        my passion for coding and commitment to quality. Let’s take a look at some of my best work!
      </p>
      <button className="explore-button" onClick={onExploreClick}>
        Discover My Work
      </button>
    </div>
  </div>
);

const MainContent = () => (
  <div className="main-content-wrapper">
    <h2 className="main-title">My Projects</h2>
    <p className="main-description">
      Here are some of my best works showcasing my skills.
    </p>

    <h2 className="project-title">Pathogen-Z</h2>
    <p className="project-description">
      Pathogen-Z is a zombie-themed game that I developed using Roblox Studio.
      I scripted the entire game, and had some help from others who built the
      map and designed the UI. The UI was scripted in React-lua, networking by Zap,
      and the game was scripted using Rojo and VSCode. The game is still in development.
      I may show you some snippets of source code if you are curious to see how it works under the hood.
    </p>

    <div className="project-content">
      <iframe
        className="project-video"
        src="https://www.youtube.com/embed/avsJ_RSyhgM"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>

      <a
        href="https://www.roblox.com/games/16771911763/Pathogen-Z"
        target="_blank"
        rel="noopener noreferrer"
        className="project-link"
      >
        Play The Game Here
      </a>
    </div>

    <h2 className="project-title">Elite Rarities</h2>
    <p className="project-description">
      Elite Rarities was a learning experience. This is where I first learned
      how to make an entire game on my own. I scripted and designed the entire
      UI for this game. It's not the best but it is functional.
      <br />
      <a href="https://github.com/4igz/elite-rarities" target="_blank" rel="noopener noreferrer">
        The source code is also available here.
      </a>
    </p>

    <div className="project-content">
      <iframe
        className="project-video"
        src="https://www.youtube.com/embed/L7rdua7_bmI"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>

    {/* Contact Section */}
    <div id="#Contact" className="contact-section">
      <h2>Contact Me</h2>
      <p>Feel free to reach out if you’d like to connect or discuss potential opportunities.</p>
      <div className="contact-links">
        <a href="mailto:4iggleton@gmail.com" className="contact-link">Email</a>
        <a href="#Contact" className="contact-link" title="Discord">
          Discord: 4igz
        </a>
        <a href="https://github.com/4igz" target="_blank" rel="noopener noreferrer" className="contact-link">
          GitHub
        </a>
      </div>
    </div>
  </div>
);
export default App;
