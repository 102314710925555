import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBRQz8Cu_128JzmjcyqUZ2C6MBHoDKV4vs",
  authDomain: "roblox-portfolio-2871e.firebaseapp.com",
  databaseURL: "https://roblox-portfolio-2871e-default-rtdb.firebaseio.com",
  projectId: "roblox-portfolio-2871e",
  storageBucket: "roblox-portfolio-2871e.firebasestorage.app",
  messagingSenderId: "679381690393",
  appId: "1:679381690393:web:f32b8f1f7898d30b3f2288",
  measurementId: "G-48P43WYG6G"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default app;